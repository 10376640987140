import React, { useState } from 'react';
import { ChakraProvider, Flex, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Whitelist from './pages/Whitelist';
import './App.css';

// Extend the theme to include custom colors, fonts, etc
const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({ 
  config,
  colors: {
    brand: {
      primary: '#BB86FC',
      secondary: '#03DAC6',
    },
  },
});

const App: React.FC = () => {
  const [account, setAccount] = useState<string | null>(null);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Flex direction="column" minHeight="100vh" bg="gray.900" color="white">
          <Header account={account} setAccount={setAccount} />
          <Flex as="main" direction="column" flex="1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/whitelist" element={<Whitelist account={account} />} />
            </Routes>
          </Flex>
          <Footer />
        </Flex>
      </Router>
    </ChakraProvider>
  );
};

export default App;
