import React from 'react';
import { Box, Container, Stack, Text, Link, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FaTwitter, FaDiscord, FaGithub } from 'react-icons/fa';

const Footer: React.FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Box
      as="footer"
      bg={bgColor}
      color={textColor}
      py={10}
      mt="auto"
    >
      <Container maxW="container.xl">
        <Stack direction={{ base: 'column', md: 'row' }} spacing={4} align="center" justify="space-between">
          <Text>© 2023 Gravity NFT. All rights reserved.</Text>
          <Stack direction="row" spacing={6}>
            <Link href="#" isExternal>
              Terms of Service
            </Link>
            <Link href="#" isExternal>
              Privacy Policy
            </Link>
          </Stack>
          <Stack direction="row" spacing={6}>
            <IconButton
              as="a"
              href="https://twitter.com/GravityNFT"
              aria-label="Twitter"
              icon={<FaTwitter />}
              size="md"
              isRound
              target="_blank"
            />
            <IconButton
              as="a"
              href="https://discord.gg/GravityNFT"
              aria-label="Discord"
              icon={<FaDiscord />}
              size="md"
              isRound
              target="_blank"
            />
            <IconButton
              as="a"
              href="https://github.com/GravityNFT"
              aria-label="GitHub"
              icon={<FaGithub />}
              size="md"
              isRound
              target="_blank"
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
