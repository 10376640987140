import React from 'react';
import { Box, Flex, Spacer, Link, Button, Text, useColorModeValue, keyframes } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import WalletConnect from './WalletConnect';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

interface HeaderProps {
  account: string | null;
  setAccount: (account: string | null) => void;
}

const Header: React.FC<HeaderProps> = ({ account, setAccount }) => {
  const bgGradient = useColorModeValue(
    'linear(to-r, purple.500, blue.500)',
    'linear(to-r, purple.700, blue.700)'
  );

  return (
    <Box
      as="header"
      bg={bgGradient}
      backgroundSize="200% 200%"
      animation={`${gradientAnimation} 5s ease infinite`}
      color="white"
      py={4}
      px={8}
      boxShadow="lg"
    >
      <Flex maxW="container.xl" mx="auto" align="center">
        <Link
          as={RouterLink}
          to="/"
          fontSize="2xl"
          fontWeight="bold"
          _hover={{ textDecoration: 'none', textShadow: '0 0 8px rgba(255,255,255,0.5)' }}
          transition="all 0.3s ease"
        >
          <Text
            bgGradient="linear(to-r, yellow.200, orange.400)"
            bgClip="text"
            fontSize="3xl"
            fontWeight="extrabold"
          >
            inversoLabs
          </Text>
        </Link>
        <Spacer />
        <Flex align="center" gap={6}>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/whitelist">Whitelist</NavLink>
          <WalletConnect account={account} setAccount={setAccount} />
        </Flex>
      </Flex>
    </Box>
  );
};

const NavLink: React.FC<{ to: string; children: React.ReactNode }> = ({ to, children }) => (
  <Link
    as={RouterLink}
    to={to}
    px={2}
    py={1}
    rounded="md"
    _hover={{
      textDecoration: 'none',
      bg: 'whiteAlpha.200',
    }}
    transition="all 0.3s ease"
  >
    {children}
  </Link>
);

export default Header;
