

import { ethers } from 'ethers';
declare global {
    interface Window {
      ethereum: any;
    }
  }
// Gravity Chain Parameters
const gravityChainParams = {
  chainId: '0x659', // 1625 in decimal
  chainName: 'Gravity Alpha Mainnet',
  nativeCurrency: {
    name: 'Gravity',
    symbol: 'G',
    decimals: 18
  },
  rpcUrls: ['https://rpc.gravity.xyz'],
  blockExplorerUrls: ['https://gscout.xyz']
};

// Check if MetaMask is installed
export const isMetaMaskInstalled = (): boolean => {
  return typeof window.ethereum !== 'undefined';
};

// Request account access
export const requestAccount = async (): Promise<string> => {
  if (!isMetaMaskInstalled()) {
    throw new Error('MetaMask is not installed');
  }
  
  try {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    return accounts[0];
  } catch (error) {
    console.error('Failed to connect to MetaMask:', error);
    throw error;
  }
};

// Add Gravity Chain to MetaMask
export const addGravityChain = async (): Promise<void> => {
  if (!isMetaMaskInstalled()) {
    throw new Error('MetaMask is not installed');
  }

  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [gravityChainParams],
    });
  } catch (error) {
    console.error('Failed to add Gravity chain:', error);
    throw error;
  }
};

// Get account balance
export const getBalance = async (address: string): Promise<string> => {
  if (!isMetaMaskInstalled()) {
    throw new Error('MetaMask is not installed');
  }

  const provider = new ethers.BrowserProvider(window.ethereum);
  try {
    const balance = await provider.getBalance(address);
    return ethers.formatEther(balance);
  } catch (error) {
    console.error('Failed to get balance:', error);
    throw error;
  }
};

// Listen for account changes
export const listenForAccountChanges = (callback: (accounts: string[]) => void) => {
  if (window.ethereum) {
    window.ethereum.on('accountsChanged', callback);
  }
};
