import React, { useState, useEffect } from 'react';
import { Box, Button, Text, VStack, useToast, Flex, Spacer } from '@chakra-ui/react';
import { db } from '../services/firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { requestAccount, getBalance } from '../services/ethereum';

interface WhitelistProps {
  account: string | null;
}

const Whitelist: React.FC<WhitelistProps> = ({ account }) => {
  const [balance, setBalance] = useState<string | null>(null);
  const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    if (account) {
      checkWhitelistStatus();
    }
  }, [account]);

  const connectWallet = async () => {
    setIsLoading(true);
    try {
      const address = await requestAccount();
      // Remove this line: setAccount(address);
      const bal = await getBalance(address);
      setBalance(bal);
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      toast({
        title: 'Failed to connect wallet',
        description: 'Please make sure MetaMask is installed and unlocked.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const checkWhitelistStatus = async () => {
    if (!account) return;

    const whitelistRef = collection(db, 'whitelist');
    const q = query(whitelistRef, where('address', '==', account));
    const querySnapshot = await getDocs(q);
    setIsWhitelisted(!querySnapshot.empty);
  };

  const joinWhitelist = async () => {
    if (!account) return;

    setIsLoading(true);
    try {
      const whitelistRef = collection(db, 'whitelist');
      await addDoc(whitelistRef, {
        address: account,
        timestamp: new Date()
      });
      setIsWhitelisted(true);
      toast({
        title: 'Joined Whitelist',
        description: 'Your address has been added to the whitelist.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error joining whitelist:', error);
      toast({
        title: 'Failed to join whitelist',
        description: 'An error occurred while adding your address to the whitelist.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex direction="column" minHeight="100vh" bg="gray.900" color="white">
      <Flex direction="column" flex="1" justify="center" align="center" p={8}>
        <VStack spacing={6} width="100%" maxWidth="600px">
          <Text fontSize="2xl" fontWeight="bold">Gravity NFT Whitelist</Text>
          {!account ? (
            <Button onClick={connectWallet} isLoading={isLoading}>
              Connect Wallet
            </Button>
          ) : (
            <>
              <Text>Connected: {account}</Text>
              <Text>Balance: {balance} G</Text>
              {isWhitelisted ? (
                <Text color="green.500">Your address is whitelisted!</Text>
              ) : (
                <Button onClick={joinWhitelist} isLoading={isLoading}>
                  Join Whitelist
                </Button>
              )}
            </>
          )}
        </VStack>
      </Flex>
      <Spacer />
    </Flex>
  );
};

export default Whitelist;
