import React, { useState, useEffect } from 'react';
import { Button, Text, VStack, HStack, useToast,useColorModeValue } from '@chakra-ui/react';
import { requestAccount, getBalance, addGravityChain, isMetaMaskInstalled, listenForAccountChanges } from '../services/ethereum';

interface WalletConnectProps {
  account: string | null;
  setAccount: (account: string | null) => void;
}

interface WalletConnectProps {
  account: string | null;
  setAccount: (account: string | null) => void;
}

const WalletConnect: React.FC<WalletConnectProps> = ({ account, setAccount }) => {
  const [balance, setBalance] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isAddingChain, setIsAddingChain] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const handleAccountsChanged = (accounts: string[]) => {
      if (accounts.length > 0) {
        setAccount(accounts[0]);
        updateBalance(accounts[0]);
      } else {
        setAccount(null);
        setBalance(null);
      }
    };

    const handleChainChanged = () => {
      window.location.reload();
    };

    if (isMetaMaskInstalled()) {
      listenForAccountChanges(handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (isMetaMaskInstalled()) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  const updateBalance = async (address: string) => {
    try {
      const bal = await getBalance(address);
      setBalance(bal);
    } catch (error) {
      console.error('Failed to get balance:', error);
    }
  };

  const handleConnect = async () => {
    if (!isMetaMaskInstalled()) {
      toast({
        title: 'MetaMask not detected',
        description: 'Please install MetaMask to connect your wallet.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsConnecting(true);
    try {
      const address = await requestAccount();
      setAccount(address);
      await updateBalance(address);
      toast({
        title: 'Wallet Connected',
        description: 'Your wallet has been successfully connected.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      toast({
        title: 'Connection Failed',
        description: 'Failed to connect your wallet. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsConnecting(false);
    }
  };

  const handleAddGravityChain = async () => {
    setIsAddingChain(true);
    try {
      await addGravityChain();
      toast({
        title: 'Gravity Chain Added',
        description: 'The Gravity chain has been successfully added to your MetaMask.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to add Gravity chain:', error);
      toast({
        title: 'Failed to Add Chain',
        description: 'There was an error adding the Gravity chain. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAddingChain(false);
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      {account ? (
        <>
          <HStack justify="space-between">
            <Text>Connected: {`${account.slice(0, 6)}...${account.slice(-4)}`}</Text>
            <Text>Balance: {balance ? `${parseFloat(balance).toFixed(4)} G` : 'Loading...'}</Text>
          </HStack>
          <Button onClick={handleAddGravityChain} isLoading={isAddingChain} loadingText="Adding Chain">
            Add Gravity Chain to MetaMask
          </Button>
        </>
      ) : (
        <Button onClick={handleConnect} isLoading={isConnecting} loadingText="Connecting">
          Connect Wallet
        </Button>
      )}
    </VStack>
  );
};

export default WalletConnect;
