import React from 'react';
import { Box, Button, Heading, Text, VStack, HStack, Image, Container, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Home: React.FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <Box  minHeight="100vh">
      <Container maxW="container.xl" py={20}>
        <VStack spacing={10} align="stretch">
          <Box textAlign="center">
            <Heading as="h1" size="2xl" mb={4}>
              A New Era of NFTs on Gravity
            </Heading>
            <Text fontSize="xl" color={textColor}>
              Discover the future of digital ownership on the Gravity blockchain <br/>
              CONECT WALLET TO ADD GRAVITY CHAIN TO METAMASK
            </Text>
          </Box>

          <HStack spacing={8} justify="center">
            <Image 
              src="https://img-cdn.magiceden.dev/rs:fill:640:0:0/plain/https://arweave.net/duW59jVFL2AwYtu03HWL5EogaHz2gqGNptcqi-yxPOw" 
              alt="AI Image" 
              width={180}
              height={180}
              
            />
            <VStack align="start" maxW="400px" spacing={4}>
              <Heading as="h2" size="lg">
                JOIN THE WHITELIST
              </Heading>
              <Text color={textColor}>
                Inverso Labs upcomping Gravity NFT release is a groundbreaking collection of digital assets powered by the Gravity blockchain. 
                Our NFTs combine stunning artistry with cutting-edge technology, offering a unique experience 
                for collectors and enthusiasts alike.
              </Text>
              <Button as={RouterLink} to="/whitelist" colorScheme="blue">
                Join Whitelist
              </Button>
            </VStack>
          </HStack>

          <Box>
            <Heading as="h2" size="lg" mb={4} textAlign="center">
              Features
            </Heading>
            <HStack spacing={8} justify="center">
              {['Unique Artwork', 'Gravity Blockchain', 'Exclusive Benefits'].map((feature) => (
                <Box key={feature} p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md">
                  <Heading fontSize="xl">{feature}</Heading>
                  <Text mt={4}>//</Text>
                </Box>
              ))}
            </HStack>
          </Box>

          <VStack spacing={4}>
            <Heading as="h2" size="lg">
              Get Started
            </Heading>
            <Text color={textColor} textAlign="center">
              Ready to dive into the world of Gravity NFTs? Join our whitelist now to get early access and exclusive benefits!
            </Text>
            <Button as={RouterLink} to="/whitelist" size="lg" colorScheme="blue">
              Join Whitelist
            </Button>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default Home;
