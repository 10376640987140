import React from 'react';
import { Box, Container, Heading, Text, VStack, HStack, Image, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

interface TeamMember {
  name: string;
  role: string;
  image: string;
  bio: string;
}

const teamMembers: TeamMember[] = [
  {
    name: "Crux Inverso",
    role: "Founder & Artist",
    image: "/path-to-alice-image.jpg",
    bio: "Cruxis a renowned digital artist with over 10 years of experience in creating unique NFT collections."
  },
  {
    name: "J. Cross",
    role: "Blockchain Developer",
    image: "/path-to-bob-image.jpg",
    bio: "J. is an expert in blockchain technology, specializing in smart contract development on EVM"
  },
  // Add more team members as needed
];

const About: React.FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <Box minHeight="100vh">
      <Container maxW="container.xl" py={20}>
        <VStack spacing={12} align="stretch">
          <Box textAlign="center">
            <Heading as="h1" size="2xl" mb={4}>
              About Gravity NFT
            </Heading>
            <Text fontSize="xl" color={textColor}>
              Revolutionizing digital ownership on the Gravity blockchain
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="xl" mb={4}>
              Our Vision
            </Heading>
            <Text color={textColor} fontSize="lg">
              Gravity NFT aims to bridge the gap between digital art and blockchain technology. 
              We believe in creating a sustainable and innovative ecosystem where artists can 
              thrive and collectors can own truly unique digital assets. By leveraging the 
              Gravity blockchain, we're able to offer low-cost, high-speed transactions while 
              maintaining the highest standards of security and authenticity.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="xl" mb={4}>
              The Gravity Advantage
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
              <Box>
                <Heading as="h3" size="lg" mb={2}>
                  Scalability
                </Heading>
                <Text color={textColor}>
                  The Gravity blockchain offers unparalleled scalability, allowing for thousands 
                  of transactions per second. This means faster minting and trading of NFTs.
                </Text>
              </Box>
              <Box>
                <Heading as="h3" size="lg" mb={2}>
                  Low Fees
                </Heading>
                <Text color={textColor}>
                  With Gravity's efficient consensus mechanism, transaction fees are kept to a 
                  minimum, making it more accessible for artists and collectors alike.
                </Text>
              </Box>
              <Box>
                <Heading as="h3" size="lg" mb={2}>
                  Interoperability
                </Heading>
                <Text color={textColor}>
                  Gravity's design allows for seamless interaction with other blockchains, 
                  opening up new possibilities for cross-chain NFT experiences.
                </Text>
              </Box>
              <Box>
                <Heading as="h3" size="lg" mb={2}>
                  Sustainability
                </Heading>
                <Text color={textColor}>
                  Our commitment to environmental responsibility is reflected in Gravity's 
                  energy-efficient proof-of-stake consensus mechanism.
                </Text>
              </Box>
            </SimpleGrid>
          </Box>

          <Box>
            <Heading as="h2" size="xl" mb={8}>
              Meet Our Team
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
              {teamMembers.map((member) => (
                <Box key={member.name} p={5} shadow="md" borderWidth="1px" borderRadius="md">
                  <VStack spacing={4}>
                    <Image 
                      src={member.image} 
                      alt={member.name} 
                      borderRadius="full" 
                      boxSize="150px" 
                      objectFit="cover"
                    />
                    <Heading as="h3" size="md">{member.name}</Heading>
                    <Text fontWeight="bold" color="blue.500">{member.role}</Text>
                    <Text color={textColor}>{member.bio}</Text>
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default About;
